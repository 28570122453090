.sliderTitleContainer {
  width: 70%;
  margin: auto;

  .title {
    color: white;
    position: relative;
    margin-bottom: -15px;
    font-size: 35px;
    z-index: 1;
  }
}

.sliderContainer {
  padding: 45px;
  position: relative;
  min-height: 376px;

  .wrapper {
    width: 70%;
    margin: auto;

    .slider {
      [class~=slick-slide] {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.titleContainer {
  margin-top: 50px;
  //background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(82,54,16,1) 31%, rgb(255, 194, 15) 60%, rgba(255,226,0,0) 74%);

  .title {
    color: white;
    position: relative;
    font-size: 35px;
    width: 70%;
    margin: auto;
  }
}

.catalogContainer {
  width: 70%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  min-height: 600px;
}

.infoContainer {
  background: #101010;
  color: white;
  padding: 35px;

  .wrapper {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .block {
      display: flex;
      padding: 0px 15px;
      &:nth-child(2) {
        border-right: 1px solid #8f8f8f;
        border-left: 1px solid #8f8f8f;
      }

      .icon {
        color: rgb(215, 176, 61)
      }

      .text {
        margin-left: 10px;
        h2 {
          margin: 0;
        }

        .textInfo {
          color: #999;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .infoContainer {
    .wrapper {
      width: 100%;
      flex-direction: column;
      .block {
        padding: 0!important;
        margin-bottom: 20px;
        border: none!important;
      }
    }
  }

  .catalogContainer {
    width: 100%;
    justify-content: center;
  }

  .sliderTitleContainer {
    width: 100%;
    padding: 0px 12px;
  }

  .titleContainer {
    width: 100%;
    padding: 0px 12px;
    .title {
      width: auto;
    }
  }

  .sliderContainer {
    padding: 0!important;

    .wrapper {
      //width: 100%;
    }
  }
}

.loader {
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 1700px) {
  .infoContainer {
    .wrapper {
      width: 1100px;
    }
  }

  .sliderTitleContainer {
    width: 1100px;
  }

  .catalogContainer {
    width: 1100px;
  }

  .sliderContainer {
    .wrapper {
      width: 1100px;
    }
  }

  .titleContainer {
    .title {
      width: 1100px;
    }
  }
}




