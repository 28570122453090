.catalog {
  width: 300px;
  margin: 20px;
  display: flex;
  height: 330px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;

  .title {
    margin: 10px;
    font-weight: bold;
    font-size: 22px;
    position: absolute;
    margin-left: 18px;
    transition: 0.5s;
  }

  .flag {
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 4px;
  }

  &:hover {
    position: relative;
    $border: 1px;
    transition: 0.5s;
    //box-shadow: 0 10px 20px rgb(0 0 0 / 75%), 0 6px 6px rgb(0 0 0 / 78%);

    //&:before {
    //  content: '';
    //  position: absolute;
    //  top: 0; right: 0; bottom: 0; left: 0;
    //  z-index: -1;
    //  margin: -$border; /* !importanté */
    //  border-radius: inherit; /* !importanté */
    //  background: linear-gradient(90deg, rgba(177,177,177,1) 0%, rgba(148,148,148,0) 26%, rgba(156,156,156,0) 75%, rgba(187,182,182,1) 100%);
    //}

    .title {
      color: white;
      font-size: 25px;
      transition: 0.5s;
    }

    .swatch {
      img {
        transition: 0.5s;
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 2px 10px rgb(39 39 39);
      }
      img:nth-child(1) {
        animation: swatch-purple-motion 5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      }

      img:nth-child(2) {
        animation: swatch-blue-motion 5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      }

      img:nth-child(3) {
        animation: swatch-green-motion 5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      }
    }
  }
}

.swatch {
  display: block;
  text-align: center;
  position: relative;
  //left: -48px;
  margin: 108px 25px;


    img {
      border-radius: 10px;
      position: absolute;
      box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 2px 10px rgb(255 255 255);
    }
  &:hover {

    //img:nth-child(1) {
    //  animation: swatch-purple-motion 5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    //}
    //
    //img:nth-child(2) {
    //  animation: swatch-blue-motion 5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    //}
    //
    //img:nth-child(3) {
    //  animation: swatch-green-motion 5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    //}
  }
}

@keyframes swatch-purple-motion {
  0% {
    transform: rotate(0deg);
  }
  30%,
  70% {
    transform: rotate(-65deg);
  }
  90%,
  10% {
    transform: rotate(0deg);
  }
}

@keyframes swatch-blue-motion {
  0% {
    transform: rotate(0deg);
  }
  30%,
  70% {
    transform: rotate(-40deg);
  }
  90%,
  10% {
    transform: rotate(0deg);
  }
}

@keyframes swatch-green-motion {
  0% {
    transform: rotate(0deg);
  }
  30%,
  70% {
    transform: rotate(-15deg);
  }
  90%,
  10% {
    transform: rotate(0deg);
  }
}
